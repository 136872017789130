import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Dexie, Table } from 'dexie';

export interface Queue {
    id?: number;
    user_id?: number;
    uuid?: string;
    reference_table: string;
    reference_id?: number;
    reference?: string;
    content?: string;
    payload?: string;
    attempts?: number;
    attempt_at?: Date;
    attempt_error?: string;
    created_at?: Date;
    history_at?: Date;
}

class QueueDatabase extends Dexie {
    queues!: Table<Queue, number>;
    queues_faileds!: Table<Queue, number>;
    queues_histories!: Table<Queue, number>;

    constructor() {
        super('fc-database');
        this.version(3).stores({
            queues: '++id,uuid,created_at',
            queues_faileds: '++id,uuid,created_at',
            queues_histories: '++id,uuid,created_at',
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class QueuesDBService {

    private db: QueueDatabase;
    private readonly QUEUES_KEY = 'fc_queues';
    private readonly FAILED_QUEUES_KEY = 'fc_failed_queues';
    private readonly QUEUES_HISTORIES_KEY = 'fc_queues_histories';
    private readonly useLocalStorage: boolean = false;

    constructor() {

        try {
            if ('indexedDB' in window) {
                this.db = new QueueDatabase();
                this.useLocalStorage = false;
            } else {
                throw new Error('IndexedDB não suportado');
            }
        } catch (error) {
            console.error('IndexedDB não disponível, usando localStorage como fallback');
            this.useLocalStorage = true;
        }
    }

    private getQueuesFromStorage(key: string): Queue[] {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : [];
    }

    private saveQueuesToStorage(key: string, queues: Queue[]): void {
        localStorage.setItem(key, JSON.stringify(queues));
    }

    async add(queue: Queue): Promise<void> {

        if (!queue.uuid) {
            queue.uuid = uuidv4();
        }
        if (!queue.user_id) {
            queue.user_id = 1;
        }
        if (!queue.created_at) {
            queue.created_at = new Date();
        }

        try {
            const sanitizedQueue = this.sanitizeQueue(queue);
            if (this.useLocalStorage) {
                const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
                queue.id = queues.length + 1;
                queues.push(sanitizedQueue);
                this.saveQueuesToStorage(this.QUEUES_KEY, queues);
            } else {
                await this.db.queues.add(sanitizedQueue);
            }
        } catch (error) {
            console.error('Error adding queue:', error, queue);
            throw error;
        }
    }

    async all(table = 'queues'): Promise<Queue[]> {
        if (this.useLocalStorage) {
            const key = table === 'queues' ? this.QUEUES_KEY : (table === 'queues_histories' ? this.QUEUES_HISTORIES_KEY : this.FAILED_QUEUES_KEY);
            return this.getQueuesFromStorage(key);
        } else {
            if (table === 'queues') {
                return this.db.queues.toArray();
            } else if (table === 'queues_histories') {
                return this.db.queues_histories.toArray();
            } else {
                return this.db.queues_faileds.toArray();
            }
        }
    }

    async get(id: number): Promise<Queue | undefined> {
        if (this.useLocalStorage) {
            const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
            return queues.find(q => q.id === id);
        } else {
            return this.db.queues.get(id);
        }
    }

    async getByUuid(uuid: string): Promise<Queue | undefined> {
        if (this.useLocalStorage) {
            const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
            return queues.find(q => q.uuid === uuid);
        } else {
            return this.db.queues.where('uuid').equals(uuid).first();
        }
    }

    async getNewestQueue(table = 'queues'): Promise<Queue | undefined> {
        if (this.useLocalStorage) {
            const key = table === 'queues' ? this.QUEUES_KEY : this.FAILED_QUEUES_KEY;
            const queues = this.getQueuesFromStorage(key);
            return queues.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0];
        } else {
            if (table === 'queues') {
                return this.db.queues.orderBy('created_at').last();
            } else {
                return this.db.queues_faileds.orderBy('created_at').last();
            }
        }
    }

    async getOldestQueue(table = 'queues'): Promise<Queue | undefined> {
        if (this.useLocalStorage) {
            const key = table === 'queues' ? this.QUEUES_KEY : this.FAILED_QUEUES_KEY;
            const queues = this.getQueuesFromStorage(key);
            return queues.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())[0];
        } else {
            if (table === 'queues') {
                return this.db.queues.orderBy('created_at').first();
            } else {
                return this.db.queues_faileds.orderBy('created_at').first();
            }
        }
    }

    async getByFields(fields: { [key: string]: string | number }, firstOnly: boolean = true): Promise<Queue[] | Queue | undefined> {
        if (this.useLocalStorage) {
            const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
            let filteredQueues = queues;

            for (const field in fields) {
                if (fields.hasOwnProperty(field)) {
                    const value = fields[field];
                    filteredQueues = filteredQueues.filter(item => item[field] === value);
                }
            }

            filteredQueues.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

            return firstOnly ? filteredQueues[0] : filteredQueues;
        } else {
            let collection = this.db.queues.toCollection();

            for (const field in fields) {
                if (fields.hasOwnProperty(field)) {
                    const value = fields[field];
                    collection = collection.filter(item => item[field] === value);
                }
            }

            if (firstOnly) {
                return collection.sortBy('created_at').then(items => items[0]);
            } else {
                return collection.sortBy('created_at');
            }
        }
    }

    async put(queue: Queue): Promise<void> {
        try {
            const sanitizedQueue = this.sanitizeQueue(queue);
            if (this.useLocalStorage) {
                const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
                const index = queues.findIndex(q => q.id === queue.id);
                if (index !== -1) {
                    queues[index] = sanitizedQueue;
                    this.saveQueuesToStorage(this.QUEUES_KEY, queues);
                }
            } else {
                console.log('put', queue);
                await this.db.queues.put(sanitizedQueue);
            }
        } catch (error) {
            console.error('Erro ao salvar queue:', error, queue);
            throw error;
        }
    }

    async delete(id: number): Promise<void> {
        if (this.useLocalStorage) {
            const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
            const filteredQueues = queues.filter(q => q.id !== id);
            this.saveQueuesToStorage(this.QUEUES_KEY, filteredQueues);
        } else {
            await this.db.queues.delete(id);
        }
    }

    async addFailed(queue: Queue): Promise<void> {
        console.error('Queue addFailed', queue);
        const sanitizedQueue = this.sanitizeQueue(queue);
        if (this.useLocalStorage) {
            const failedQueues = this.getQueuesFromStorage(this.FAILED_QUEUES_KEY);
            sanitizedQueue.id = failedQueues.length + 1;
            failedQueues.push(sanitizedQueue);
            this.saveQueuesToStorage(this.FAILED_QUEUES_KEY, failedQueues);
        } else {
            await this.db.queues_faileds.add(sanitizedQueue);
        }
    }

    async deleteOldFailedQueues(): Promise<void> {
        const dateLimit = new Date();
        dateLimit.setDate(dateLimit.getDate() - 30);

        if (this.useLocalStorage) {
            const failedQueues = this.getQueuesFromStorage(this.FAILED_QUEUES_KEY);
            const filteredQueues = failedQueues.filter(q => new Date(q.created_at) > dateLimit);
            this.saveQueuesToStorage(this.FAILED_QUEUES_KEY, filteredQueues);
        } else {
            const keysToDelete = await this.db.queues_faileds
                .where('created_at')
                .below(dateLimit)
                .primaryKeys();

            await this.db.queues_faileds.bulkDelete(keysToDelete);
        }
    }

    async moveToHistory(id: number): Promise<void> {
        try {
            if (this.useLocalStorage) {
                const queues = this.getQueuesFromStorage(this.QUEUES_KEY);
                const histories = this.getQueuesFromStorage(this.QUEUES_HISTORIES_KEY);
                const queueIndex = queues.findIndex(q => q.id === id);

                if (queueIndex !== -1) {
                    const queue = queues[queueIndex];
                    queue.id = histories.length + 1;
                    queue.history_at = new Date();
                    histories.push(queue);
                    queues.splice(queueIndex, 1);

                    this.saveQueuesToStorage(this.QUEUES_HISTORIES_KEY, histories);
                    this.saveQueuesToStorage(this.QUEUES_KEY, queues);
                }
            } else {
                const queue = await this.db.queues.get(id);
                if (queue) {
                    queue.history_at = new Date();
                    await this.db.queues_histories.add(queue);
                    await this.db.queues.delete(id);
                }
            }

            await this.limitHistorySize();
        } catch (error) {
            console.error('Error moving queue to history:', error);
            throw error;
        }
    }

    async limitHistorySize(maxRecords: number = 100): Promise<void> {
        try {
            if (this.useLocalStorage) {
                const histories = this.getQueuesFromStorage(this.QUEUES_HISTORIES_KEY);
                if (histories.length > maxRecords) {

                    const sortedHistories = histories.sort((a, b) =>
                        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                    );
                    const limitedHistories = sortedHistories.slice(0, maxRecords);
                    this.saveQueuesToStorage(this.QUEUES_HISTORIES_KEY, limitedHistories);
                }
            } else {

                const totalRecords = await this.db.queues_histories.count();

                if (totalRecords > maxRecords) {

                    const recordsToDelete = totalRecords - maxRecords;

                    const keysToDelete = await this.db.queues_histories
                        .orderBy('created_at')
                        .limit(recordsToDelete)
                        .primaryKeys();

                    await this.db.queues_histories.bulkDelete(keysToDelete);
                }
            }
        } catch (error) {
            console.error('Error limiting history size:', error);
            throw error;
        }
    }

    private sanitizeQueue(queue: Queue): Queue {
        return {
            ...queue,
            attempt_error: this.formatError(queue.attempt_error),
            created_at: queue.created_at ? new Date(queue.created_at) : undefined,
            attempt_at: queue.attempt_at ? new Date(queue.attempt_at) : undefined,
            history_at: queue.history_at ? new Date(queue.history_at) : undefined,
            payload: queue.payload ?
                (typeof queue.payload === 'object' ?
                    JSON.stringify(queue.payload) :
                    queue.payload
                ) : undefined
        };
    }

    private formatError(error: any): string {
        if (!error) return '';

        // Se já for uma string, retorna diretamente
        if (typeof error === 'string') return error;

        // Se for um objeto de erro ou objeto genérico
        const errorObj = {
            message: error.message || String(error),
            name: error.name,
            status: error.status,
            statusText: error.statusText,
            // Para erros HTTP
            url: error.url,
            // Stack trace se disponível
            stack: error.stack,
            // Se tiver dados adicionais
            data: error.error || error.data,
            // Timestamp do erro
            timestamp: new Date().toISOString()
        };

        // Remove propriedades undefined/null
        Object.keys(errorObj).forEach(key =>
            errorObj[key] === undefined && delete errorObj[key]
        );

        return JSON.stringify(errorObj);
    }
}
